import React from 'react'
import styled from 'styled-components'
import Logo from '@components/logo'
import { SocialIcon } from 'react-social-icons'

const Container = styled.footer`
  padding: calc(${({ theme }) => theme.padding} * 4);
  background: ${({ theme }) => theme.colors.textGrey};
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const LogoContainer = styled.div`
  color: white;
  a {
    color: white;
    display: block;
    margin-top: 10px;
    text-decoration: none;
  }
`
const SocialContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.padding} 0;
`

const AddrContainer = styled.div`
  a {
    color: white;
    display: block;
    margin-top: 10px;
    text-decoration: none;
    letter-spacing: 2px;
  }
`

const SocialWrap = styled.div`
  background: white;
  border-radius: 50%;
  padding: 5px;
  margin-left: -5px;
`

const socials = [
  'https://www.facebook.com/jack.webmarketing',
  'https://www.instagram.com/jack.webmarketing',
  'https://www.linkedin.com/company/jackandrussells',
]

const Footer = () => (
  <Container>
    <LogoContainer>
      <a href="https://www.jackandrussells.com">
        <Logo white />
      </a>
      <AddrContainer>
        <a href="mailto:hello@jackandrussells.com">hello@jackandrussells.com</a>
        <a href="tel:06 59 39 91 10">06 59 39 91 10</a>
      </AddrContainer>
    </LogoContainer>
    <SocialContainer>
      {socials.map((url) => (
        <SocialWrap key={url}>
          <SocialIcon url={url} />
        </SocialWrap>
      ))}
    </SocialContainer>
  </Container>
)

export default Footer
