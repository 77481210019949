import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Keypoint from './Keypoint'

const Container = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin: calc(${({ theme }) => theme.padding} * 2);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

const Keypoints = ({ keypointsData }) => (
  <Container>
    {keypointsData.map((keypoint, index) => (
      <Fade key={keypoint.title} top delay={index * 400}>
        <Keypoint {...keypoint} />
      </Fade>
    ))}
  </Container>
)

Keypoints.propTypes = {
  keypointsData: PropTypes.array.isRequired,
}

export default Keypoints
