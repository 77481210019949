import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.article`
  margin: calc(${({ theme }) => theme.padding} * 1);
  padding: calc(${({ theme }) => theme.padding} * 1);
  background: #f3f3f3;
`
const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 500;
  margin: calc(${({ theme }) => theme.padding} * 2) 0
    ${({ theme }) => theme.padding};
  letter-spacing: 2px;
  text-align: center;
`

const Subtitle = styled.h2`
  text-align: center;
`
const IconContainer = styled.div`
  height: 100px;
  margin: calc(${({ theme }) => theme.padding} * 2) 0;
  text-align: center;
`
const Icon = styled.img`
  object-fit: contain;
  height: 100%;
`

const Description = styled.div`
  line-height: 1.33;
  padding: calc(${({ theme }) => theme.padding} * 2);
  background: ${({ theme }) => theme.colors.red};
  color: white;
`

const Keypoint = ({ title, icon, subtitle, description }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <IconContainer>
      <Icon src={icon.asset.url} alt="" />
    </IconContainer>
    <Description>{description}</Description>
  </Container>
)

Keypoint.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.object,
}

export default Keypoint
