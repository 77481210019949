import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledButton = styled.button`
  padding: ${({ theme }) => `calc(${theme.padding} / 2) ${theme.padding}`};
  margin: ${({ theme }) => theme.padding};
  display: inline-block;
  border: 0;
  color: white;
  background: ${({ theme }) => theme.form.ctaColor};
  box-shadow: 0 3px 0 ${({ theme }) => theme.form.ctaColorDarken};
  outline: none;
  letter-spacing: 1px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.form.ctaColorDarken};
  }
  font-size: 4vw;
  font-family: 'Avenir Next', sans-serif;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.5vw;
  }
`

const Button = ({ text }) => <StyledButton>{text}</StyledButton>

Button.propTypes = {
  text: PropTypes.string,
}

export default Button
