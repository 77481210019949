import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const Seo = ({ title }) => (
  <Helmet
    htmlAttributes={{
      lang: 'fr',
    }}
    title={title}
    titleTemplate="%s | Agence Webmarketing | Toulon - Var"
  >
    <link
      rel="stylesheet"
      type="text/css"
      charset="UTF-8"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    />
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href="https://www.jackandrussells.com/assets/images/apple-icon-57x57.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="https://www.jackandrussells.com/assets/images/apple-icon-60x60.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="https://www.jackandrussells.com/assets/images/apple-icon-72x72.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="https://www.jackandrussells.com/assets/images/apple-icon-76x76.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="https://www.jackandrussells.com/assets/images/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="https://www.jackandrussells.com/assets/images/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="https://www.jackandrussells.com/assets/images/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="https://www.jackandrussells.com/assets/images/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="https://www.jackandrussells.com/assets/images/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="https://www.jackandrussells.com/assets/images/android-icon-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="https://www.jackandrussells.com/assets/images/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="https://www.jackandrussells.com/assets/images/favicon-96x96.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="https://www.jackandrussells.com/assets/images/favicon-16x16.png"
    />
  </Helmet>
)

Seo.propTypes = {
  title: PropTypes.string,
}

export default Seo
