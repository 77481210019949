import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'
import Testimony from './Testimony'

const Container = styled.section`
  padding: calc(${({ theme }) => theme.padding} * 2);
  background: ${({ theme }) => theme.colors.bgGrey};
`

const Title = styled.h2`
  text-align: center;
  font-size: 5vw;
  letter-spacing: 2px;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.textGrey};
  margin: calc(${({ theme }) => theme.padding} * 2) 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 2vw;
    letter-spacing: 5px;
  }
`

const SliderContainer = styled.div`
  padding: ${({ theme }) => theme.padding};
`

const sliderSettings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Testimonies = ({ testimonies }) => (
  <Container>
    <Title>
      <Fade cascade top>
        Nos clients parlent de nous
      </Fade>
    </Title>
    <SliderContainer>
      <Slider {...sliderSettings}>
        {testimonies.map((t) => (
          <SliderContainer key={t.id}>
            <Testimony {...t} />
          </SliderContainer>
        ))}
      </Slider>
    </SliderContainer>
  </Container>
)

Testimonies.defaultProps = {
  testimonies: [],
}

Testimonies.propTypes = {
  testimonies: PropTypes.array,
}

export default Testimonies
