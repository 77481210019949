import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Logo = styled.span`
  font-family: 'Jack', 'Proxima Nova', sans-serif;
  font-size: ${({ theme }) => theme.logo.fontSize};
  color: ${({ white }) => (white ? 'white' : 'inherit')};
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: calc(${({ theme }) => theme.logo.fontSize} / 2);
  }
`

const Red = styled.span`
  color: ${({ white, theme }) => (white ? 'white' : theme.colors.red)};
`

const LogoComponent = ({ white, allwhite }) => (
  <Logo white={white || allwhite}>
    jack<Red white={allwhite}>.</Red>
  </Logo>
)

LogoComponent.defaultProps = {
  white: false,
  allwhite: false,
}

LogoComponent.propTypes = {
  white: PropTypes.bool,
  allwhite: PropTypes.bool,
}

export default LogoComponent
