import React from 'react'
import styled from 'styled-components'
import Button from '@components/cta'
import arrowGif from '@static/arrow.original.gif'

const Input = styled.input`
  padding: ${({ theme }) => theme.padding};
  margin: ${({ theme }) => theme.padding} 0;
  font-family: 'Avenir Next', sans-serif;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.form.inputColor};
  width: 100%;
  font-size: 3.5vw;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1vw;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: 'Avenir Next', sans-serif;
  max-width: 750px;
  margin: auto;
  margin-top: ${({ theme }) => theme.padding};
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: auto;
    margin-top: 0;
  }
`
const InputsContainer = styled.div`
  display: flex;
  align-items: center;
`
const StyledArrowGif = styled.img`
  width: 40px;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: block;
  }
`

const FormComponent = () => (
  <Form method="POST" action="https://io.jackandrussells.com/api/sender/send">
    <input type="hidden" name="_jessica" />
    <input
      type="hidden"
      name="_token"
      value={process.env.GATSBY_CLIENT_TOKEN}
    />
    <Input type="text" name="nom" placeholder="Votre nom" required />
    <Input
      type="text"
      name="email"
      placeholder="Votre email ou numero de téléphone"
      required
    />
    <InputsContainer>
      <Button text="Dites m'en plus !" />
      <StyledArrowGif src={arrowGif} alt="" />
    </InputsContainer>
  </Form>
)

export default FormComponent
