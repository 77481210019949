import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import Logo from '@components/logo'
import Form from '@components/form'
import Fade from 'react-reveal/Fade'
import RubberBand from 'react-reveal/RubberBand'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax'

const Container = styled.div`
  min-height: 100vh;
  padding: ${({ theme }) => theme.padding};
  position: relative;
  background: ${({ theme }) => theme.colors.red};
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
  }
`

const DataContainer = styled.div`
  padding: calc(${({ theme }) => theme.padding}*3);
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.title.fontSize};
  font-weight: 500;
  color: #0e0e0e;
  margin-top: calc(${({ theme }) => theme.padding} * 2);
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: calc(${({ theme }) => theme.title.fontSize} / 2);
  }
`

const Subline = styled.h2`
  color: #222;
  margin: calc(${({ theme }) => theme.padding} * 2) 0;
  font-size: calc(${({ theme }) => theme.title.fontSize} / 1.1);
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: calc(${({ theme }) => theme.title.fontSize} / 2.5);
  }
`

const FormContainer = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.padding};
  }
`

const LogoPad = styled.div`
  margin-left: calc(${({ theme }) => theme.padding} * 1);
`

const logoTheme = {
  logo: {
    fontSize: '8vw',
  },
}

const ImageContainer = styled.div``

const Header = ({ title, subline, cover }) => (
  <Container>
    <DataContainer>
      <ThemeProvider theme={logoTheme}>
        <LogoPad>
          <Logo allwhite />
        </LogoPad>
      </ThemeProvider>
      <Fade top>
        <Title>{title}</Title>
      </Fade>
      <RubberBand delay={1500}>
        <Subline>{subline}</Subline>
      </RubberBand>
      <FormContainer>
        <Form />
      </FormContainer>
    </DataContainer>
    <ImageContainer>
      <Parallax y={[-20, 20]}>
        <GatsbyImage
          image={cover.asset.gatsbyImageData}
          alt=""
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center bottom',
          }}
        />
      </Parallax>
    </ImageContainer>
  </Container>
)

Header.propTypes = {
  title: PropTypes.string,
  subline: PropTypes.string,
  cover: PropTypes.object,
}

export default Header
