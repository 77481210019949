import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import BlockContent from '@sanity/block-content-to-react'

const Container = styled.section`
  background: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: grid;
    grid-template-columns: 2fr 4fr;
  }
`

const ImageContainer = styled.div`
  position: relative;
  min-height: 50vh;
`
const ImageInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`

const TextContainer = styled.div`
  padding: calc(${({ theme }) => theme.padding} * 3);
  position: relative;
  line-height: 1.33;
  overflow: hidden;
  font-size: 5vw;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2vw;
  }
`

const Signature = styled.div`
  position: relative;
  margin-top: calc(${({ theme }) => theme.padding} * 2);
  padding-top: ${({ theme }) => theme.padding};
  line-height: 1.25;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.5vw;
  }
  &::before {
    position: absolute;
    top: 0;
    content: '';
    width: 70px;
    height: 3px;
    background: ${({ theme }) => theme.colors.red};
  }
`

const Name = styled.div`
  font-weight: 500;
  display: block;
`

const Society = styled.div`
  letter-spacing: 2px;
  color: ${({ theme }) => theme.colors.textGrey};
`

const Logo = styled(GatsbyImage)`
  width: 120px;
  height: 60px;
`

/*
 * Pour le moment ce composant est bien trop couplé à Gatsby et sanity
 */
const Testimony = ({
  nameTestify,
  societyTestify,
  societyLogo,
  cover,
  _rawTextContent,
}) => (
  <Container>
    <ImageContainer>
      <ImageInner>
        <GatsbyImage
          image={cover.asset.gatsbyImageData}
          alt={nameTestify}
          style={{
            height: '100%',
            width: '100%',
          }}
          objectFit="cover"
          objectPosition="top center"
        />
      </ImageInner>
    </ImageContainer>
    <TextContainer>
      <Logo
        image={societyLogo.asset.gatsbyImageData}
        alt={societyTestify}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: 'center center',
        }}
      />
      <BlockContent blocks={_rawTextContent} />
      <Signature>
        <Name>{nameTestify}</Name>
        <Society>{societyTestify}</Society>
      </Signature>
    </TextContainer>
  </Container>
)

Testimony.propTypes = {
  nameTestify: PropTypes.string,
  societyTestify: PropTypes.string,
  cover: PropTypes.object,
  societyLogo: PropTypes.object,
  _rawTextContent: PropTypes.array,
}

export default Testimony
