import React from 'react'
import Header from '@components/header'
import Testimonies from '@components/testimonies'
import Keypoints from '@components/keypoints'
import Seo from '@components/seo'
import Footer from '@components/footer'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

const filterThisLp = (idLandingPage) => (withLpArray) =>
  withLpArray.filter((withLpObject) => {
    const landingPagesId = withLpObject.landingPages.map((lp) => lp.id)
    return landingPagesId.indexOf(idLandingPage) > -1
  })

const Home = ({ data }) => (
  <>
    <Seo title={data.lp.title} />
    <Header
      title={data.lp.accroche}
      subline={data.lp.subline}
      cover={data.lp.cover}
    />
    <Keypoints keypointsData={filterThisLp(data.lp.id)(data.keypoints.nodes)} />
    <Testimonies
      testimonies={filterThisLp(data.lp.id)(data.testimonies.nodes)}
    />
    <Footer />
  </>
)

Home.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query PageQuery {
    lp: sanityLandingPage {
      id
      accroche
      title
      subline
      cover {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    testimonies: allSanityTestify {
      nodes {
        id
        nameTestify
        societyTestify
        societyLogo {
          asset {
            gatsbyImageData(width: 120, height: 120)
          }
        }
        _rawTextContent
        cover {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
        landingPages {
          id
        }
      }
    }
    keypoints: allSanityKeypoint {
      nodes {
        title
        subtitle
        description
        icon {
          asset {
            url
          }
        }
        landingPages {
          id
        }
      }
    }
  }
`

export default Home
